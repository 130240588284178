<template>
  <section class="view">
    <div class="palm">
      <h3
        class="pheonix"
        style="
          margin-top: 10px;
          margin-bottom: 2px;
          text-shadow: 1px 0 10px white;
          color: #0e82a7;
        "
      >
        Nautical Bowls Phoenix West Valley
      </h3>
      <div class="socials">
        <div class="download-section">
          <h3 class="socials-title connect">CONNECT WITH US</h3>
          <socials-component></socials-component>
        </div>
        <div class="download-section">
          <h3 class="socials-title" style="margin-bottom: 0px">
            DOWNLOAD THE APP
          </h3>
          <h3 class="socials-title2" style="margin-left: 5px; margin-right: 5px;">For a free bowl after first purchase!</h3>
          <div class="button-container">
            <a
              href="https://apps.apple.com/us/app/nautical-bowls/id1600406554"
              target="_BLANK"
            >
              <img
                class="btn"
                loading="lazy"
                width="190"
                height="50"
                src="https://nauticalbowls.com/wp-content/uploads/2023/05/NB_appstore.svg"
                alt="apple store download button"
                role="presentation"
                data-uw-rm-alt="SVG"
              />
            </a>
            <a
              href="https://play.google.com/store/apps/details?id=com.paytronix.client.android.app.nauticalbowls"
              target="_BLANK"
            >
              <img
                loading="lazy"
                width="190"
                height="50"
                src="https://nauticalbowls.com/wp-content/uploads/2023/05/NB_googlepay.svg"
                class="btn"
                alt="google store download button"
                role="presentation"
                data-uw-rm-alt="SVG"
              />
            </a>
          </div>
        </div>

        <div class="download-section" style="gap: 5px">
          <h3 class="socials-title connect">REVIEW US</h3>
          <img
            style="margin-left: 9px"
            loading="lazy"
            width="190"
            height="50"
            src="https://nauticalbowls.com/wp-content/uploads/2023/05/NB_google_review.svg"
            class="attachment-full size-full wp-image-336"
            alt="google review image with 5 stars"
            data-uw-rm-alt-original=""
            role="presentation"
            data-uw-rm-alt="SVG"
          />
        </div>
      </div>
      <div
        style="margin-bottom: 15%"
        class="home-container greeting-container container2"
      >
        <div class="col-flex">
          <h3
            class="saying"
            style="
              color: #ecfbfe;
              text-shadow: 1px 0 10px black;
              text-align: center;
              margin-top: 0px;
              margin-bottom: 0px;
            "
          >
            At Nautical Bowls we combine healthy, fresh
            <span
              style="
                color: #0e82a7;
                text-decoration: underline;
                text-shadow: 1px 1px 2px white, 0 0 1em white, 0 0 0.2em white;
              "
              >SUPERFOOD</span
            >
            bowls and an uplifting atmosphere—giving you energy to live a full
            life!
          </h3>
        </div>
      </div>
    </div>
    <div class="pictures">
      <img
        src="../assets/opening.jpg"
        alt="people at the ribbon cutting ceremony"
        class="opening"
      />
      <img
        src="../assets/opening2.jpg"
        alt="girl with dark hair looking at 3 different bowls"
        class="opening"
      />
      <img
        src="../assets/people.jpg"
        alt="a family wearing blue and standing in front of a balloon arch"
        class="opening"
      />
    </div>
    <div class="boat">
      <div class="container container2">
        <h3 style="margin-bottom: 0px; margin-top: 0px">Signature Bowls</h3>
        <p class="sig" style="text-align: center; margin-bottom: 0px">
          Specially curated bowls you can find at every Nautical Bowls location
        </p>
      </div>
    </div>
    <div class="menu">
      <div class="bowl-container">
        <img
          style="margin-top: 30px"
          src="../assets/nauti-bowl.png"
          alt="nauti bowl"
          class="bowl-img"
        />
        <h3 class="label">The Nauti Bowl</h3>
        <p style="font-size: 14px; text-align: center">
          Açaí, Pitaya, Granola, Banana, Cacao Nibs, Peanut Butter
        </p>
        <p style="font-size: 14px; text-align: center">576 cal</p>
      </div>
      <div class="bowl-container">
        <img
          style="margin-top: 30px"
          src="../assets/anchor-bowl.png"
          alt="anchor bowl"
          class="bowl-img"
        />
        <h3 class="label">Anchor Bowl</h3>
        <p style="font-size: 14px; text-align: center">
          Açaí, Granola, Banana, Coconut Flakes, Cashew Cacao Butter
        </p>
        <p style="font-size: 14px; text-align: center">492 cal</p>
      </div>
      <div class="bowl-container">
        <img
          style="margin-top: 30px"
          src="../assets/blue-bay-bowl.png"
          alt="blue bay bowl"
          class="bowl-img"
        />
        <h3 class="label">Blue Bay Bowl</h3>
        <p style="font-size: 14px; text-align: center">
          Blue Majik, Açaí, Coconut, Spirulina Chia, Granola, Banana,
          Strawberries, Coconut Butter Crumbles
        </p>
        <p style="font-size: 14px; text-align: center">670 cal</p>
      </div>
      <div class="bowl-container">
        <img
          style="margin-top: 30px"
          src="../assets/big-island-bowl.png"
          alt="big island bowl"
          class="bowl-img"
        />
        <h3 class="label">Big Island Bowl</h3>
        <p style="font-size: 14px; text-align: center">
          Blue Majik, Açaí, Pitaya, Mango, Coconut, Vanilla Chia, Granola,
          Strawberries, Goji Berries, Hemp Seeds
        </p>
        <p style="font-size: 14px; text-align: center">455 cal</p>
      </div>
      <div class="bowl-container">
        <img
          style="margin-top: 30px"
          src="../assets/bouy-bowl.png"
          alt="bouy bowl"
          class="bowl-img"
        />
        <h3 class="label">Bouy Bowl</h3>
        <p style="font-size: 14px; text-align: center">
          Açaí, Vanilla Chia, Banana, Cacao Nibs, Pumpkin Seeds, Almond Butter
        </p>
        <p style="font-size: 14px; text-align: center">645 cal</p>
      </div>
      <div class="bowl-container">
        <img
          style="margin-top: 30px"
          src="../assets/paddle-bowl.png"
          alt="paddle bowl"
          class="bowl-img"
        />
        <h3 class="label">Paddle Bowl</h3>
        <p style="font-size: 14px; text-align: center">
          Blue Majik, Mango, Coconut, Granola, Strawberries, Coconut Flakes,
          Honey
        </p>
        <p style="font-size: 14px; text-align: center">507 cal</p>
      </div>
      <div class="bowl-container">
        <img
          style="margin-top: 30px"
          src="../assets/sail-bowl.png"
          alt="sail bowl"
          class="bowl-img"
        />
        <h3 class="label">Sail Bowl</h3>
        <p style="font-size: 14px; text-align: center">
          Açaí, Pitaya, Peanut Butter Cacao Chia, Banana, Strawberries, Cacao
          Nibs, Coconut Butter Crumbles
        </p>
        <p style="font-size: 14px; text-align: center">370 cal</p>
      </div>
      <div class="bowl-container">
        <img
          style="margin-top: 30px"
          src="../assets/sunrise-bowl.png"
          alt="sunrise bowl"
          class="bowl-img"
        />
        <h3 class="label">Sunrise Bowl</h3>
        <p style="font-size: 14px; text-align: center">
          Blue Majik, Pitaya, Granola, Banana, Strawberries, Honey
        </p>
        <p style="font-size: 14px; text-align: center">485 cal</p>
      </div>
      <div class="bowl-container">
        <img
          style="margin-top: 30px"
          src="../assets/surf-bowl.png"
          alt="surf bowl"
          class="bowl-img"
        />
        <h3 class="label">Surf Bowl</h3>
        <p style="font-size: 14px; text-align: center">
          Açaí, Coconut, Cacao, Granola, Banana, Hemp Seeds, Peanut Butter
        </p>
        <p style="font-size: 14px; text-align: center">808 cal</p>
      </div>
      <div class="bowl-container">
        <img
          style="margin-top: 30px"
          src="../assets/protein-bowl.png"
          alt="protein bowl"
          class="bowl-img"
        />
        <h3 class="label">Protein Bowl</h3>
        <p style="font-size: 14px; text-align: center">
          Açaí, PB Cacao Chia, Granola, Peanut Butter, Bananas, PB Protein
          Crumbles, Hemp Seeds
        </p>
        <p style="font-size: 14px; text-align: center">810 cal</p>
      </div>
      <div class="bowl-container" style="padding-left: 0px; padding-right: 0px">
        <img
          class="gif"
          loading="lazy"
          decoding="async"
          width="300"
          height="300"
          src="../assets/nautical-bowls-specialty-bowls.gif"
          alt="specialty bowl"
          data-uw-rm-alt="BE"
        />
        <h3 class="label" style="margin-top: 0px">Store Special</h3>
        <p style="font-size: 14px; text-align: center">
          Check out our local store menus for a chance to try a local speciatly
        </p>
        <p style="font-size: 14px; text-align: center">TBD cal</p>
      </div>
    </div>
    <div class="catering">
      <a class="a" href="catering-1.pdf" target="_BLANK" download="catering-1"
        ><img
          src="../assets/catering-1.png"
          alt="buy 20 get 10 free bowls offer"
          class="pdf"
      /></a>
      <a
        class="a"
        href="catering-bag-1.pdf"
        target="_BLANK"
        download="catering-2"
        ><img
          src="../assets/catering-bag-1.png"
          alt="buy four get one free offer"
          class="pdf"
      /></a>
      <a
        class="a catering-margin"
        href="catering-bag-2.pdf"
        target="_BLANK"
        download="catering-3"
        ><img
          src="../assets/catering-bag-2.png"
          alt="second page of catering offer"
          class="pdf"
      /></a>
    </div>
    <div class="new-location">
      <h3 style="margin-bottom: 0px">New Location Coming Soon</h3>
      <h3 class="location-address">
        SW Corner of 91st and Cardinals Way, Glendale, AZ, 85305
      </h3>
      <div class="pictures">
        <img
          src="../assets/location.png"
          alt="where the new location will be constructed"
          class="opening"
        />
        <img
          src="../assets/location2.png"
          alt="construction workers on site of new location"
          class="opening"
        />
        <img
          src="../assets/location3.png"
          alt="state farm stadium"
          class="opening"
        />
      </div>
    </div>
    <div class="background">
      <div
        style="margin-bottom: 0px"
        class="container greeting-container container2"
      >
        <img src="../assets/coffee.png" class="bowl2" alt="coffee" />
        <div class="col-flex">
          <router-link
            class="router-link"
            to="/contact"
            style="text-decoration: none"
            ><h3 class="west link">Contact Us</h3></router-link
          >

          <p>We would love to hear from you!</p>
          <p class="hide">
            Email Us At: danielle.miglio@nauticalbowls.com Or Call Us At:
            623-806-6020
          </p>
          <socials-component></socials-component>
        </div>
      </div>
    </div>

    <div class="pictures">
      <img
        src="../assets/neon-sign.jpg"
        alt="new sign on the side of building"
        class="opening"
      />
      <img
        src="../assets/opening-people.png"
        alt="two people smiling while holding a nautical bowl"
        class="opening"
      />
      <img
        src="../assets/people2.jpg"
        alt="two people smiling and holding two nautical bowls"
        class="opening"
      />
    </div>
  </section>
</template>

<script>
import SocialsComponent from "../components/SocialsComponent.vue";

export default {
  name: "HomeView",
  components: { SocialsComponent },
};
</script>

<style scoped>
.saying {
  font-size: 30px;
}

.home-container {
  background-image: url("../assets/darkwaves.png");
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80%;
  max-width: 1150px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  -webkit-box-shadow: 3px 5px 6px white;
  -moz-box-shadow: 3px 5px 6px white;
  box-shadow: 3px 3px 10px white;
  margin-top: 2%;
  margin-bottom: 10%;
  padding: 20px;
  min-height: 30vh;
}

.opening {
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  -webkit-box-shadow: 3px 5px 6px #0e82a7;
  -moz-box-shadow: 3px 5px 6px #0e82a7;
  box-shadow: 3px 3px 10px #0e82a7;
  width: 160px;
}

.greeting {
  margin-bottom: 15px;
}

.socials-title {
  font-size: 30px;
  margin-bottom: 10px;
  margin-top: 20px;
}

.socials-title2 {
  font-size: 25px;
  margin-bottom: 5px;
  margin-top: 0px;
}

.link:hover {
  filter: brightness(85%);
  transition: ease-in-out 0.3s;
  color: #ecfbfe;
}

.coffee {
  width: 170px;
}

.coffee-flex {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.label {
  font-size: 30px;
}

.bowl {
  width: 150px;
}

.bowl2 {
  width: 100px;
}

.app-store {
  margin-right: 20px;
}

.download-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  align-items: center;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.btn {
  width: 150px;
}

.btn:hover {
  filter: brightness(85%);
  transition: ease-in-out 0.3s;
}

.pictures {
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 30px;
  margin-top: 30px;
  gap: 20px;
}

.col-flex {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.west {
  margin-bottom: 0px;
  margin-top: 10px;
}

.boat {
  width: 100%;
  background-image: url("../assets/sailboat.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 80vh;
}

.menu {
  background-image: url("../assets/shiplap.png");
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 15px;
}

.container2 {
  background-color: #ecfbfee4;
  margin-bottom: 0%;
  margin-top: 2%;
}

.bowl-container {
  background-color: #b7d2d2;
  max-width: min-content;
  min-height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  -webkit-box-shadow: 3px 5px 6px #b7d2d2;
  -moz-box-shadow: 3px 5px 6px #b7d2d2;
  box-shadow: 3px 3px 10px #b7d2d2;
  padding: 20px;
  margin-top: 30px;
}

.bowl-img {
  width: 150px;
}

.gif {
  width: 190px;
  height: 190px;
}

.catering {
  min-height: 50vh;
  background-color: #ecfbfe;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
}

.new-location {
  min-height: 50vh;
  background-image: url("../assets/shiplap.png");
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: center;
}

.pdf {
  width: 150px;
  height: 200px;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  border-radius: 30px;
  -webkit-box-shadow: 3px 5px 6px white;
  -moz-box-shadow: 3px 5px 6px white;
  box-shadow: 3px 3px 10px white;
  border: 5px solid #0e82a7;
}

.pdf:hover {
  cursor: pointer;
  filter: brightness(85%);
  transition: ease-in-out 0.3s;
}

.a {
  display: flex;
  align-items: center;
  justify-content: center;
}

.background {
  width: 100%;
  background-image: url("../assets/dock.png");
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.palm {
  background-color: rgb(199, 228, 232);
  width: 100%;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

.location-address {
  margin-bottom: 0px;
  margin-top: 0px;
  font-size: 20px;
  padding-left: 10px;
  padding-right: 10px;
}

@media only screen and (min-width: 270px) {
  .opening {
    width: 200px;
  }
}

@media only screen and (max-width: 412px) {
  .hide {
    display: none;
  }
}

@media only screen and (max-width: 550px) {
  .catering {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }

  .pdf {
    width: 150px;
    height: 200px;
    margin-top: 30px;
  }

  .catering-margin {
    margin-bottom: 30px;
  }

  .sig {
    font-size: 20px;
  }

  .pheonix {
    font-size: 40px;
    padding-left: 10px;
    padding-right: 10px;
  }
}

@media only screen and (min-width: 551px) {
  .pdf {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .sig {
    font-size: 26px;
  }

  .pheonix {
    font-size: 70px;
  }

  .saying {
    font-size: 40px;
  }
}

@media only screen and (min-width: 685px) {
  .opening {
    width: 200px;
  }

  .pictures {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .location-address {
    margin-bottom: 0px;
    margin-top: 0px;
    font-size: 30px;
  }
}

@media only screen and (min-width: 768px) {
  .palm {
    background-image: url("../assets/bowl-background.jpg");
  }

  .pdf {
    width: 220px;
    height: 290px;
  }

  .gif {
    width: 300px;
    height: 300px;
  }

  .west {
    margin-top: 0px;
  }

  .bowl {
    width: 280px;
  }

  .bowl2 {
    width: 200px;
  }

  .greeting-container {
    flex-direction: row;
    margin-bottom: 5%;
  }

  p {
    width: 70%;
    padding-left: 3%;
  }

  .button-container {
    flex-direction: row;
    gap: 18px;
  }

  .btn {
    width: 200px;
  }

  .socials {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
  }

  .bowl-img {
    width: 250px;
  }

  .socials-title {
    margin-bottom: 28px;
  }
}

@media only screen and (min-width: 1020px) {
  .opening {
    width: 300px;
  }
}

@media only screen and (min-width: 1290px) {
  .opening {
    width: 400px;
  }
}
</style>
